<template>
  <div class="news-list">
    <el-row
      type="flex"
      class="row-bg"
      justify="space-between"
      align="center">
      <el-col :span="12">
        <div class="title">
          <el-dropdown
            split-button
            type="primary"
            @click="deleteSelected">
            Действия
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>Удалить выбранные</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="right-text">
          <el-button
            :loading="false"
            icon="el-icon-plus"
            type="primary"
            @click="$router.push('/creating-news')">
            Добавить новость
          </el-button>
        </div>
      </el-col>
    </el-row>
    <br>
    <el-table
      :lazy="true"
      :data="tableData"
      style="width: 100%"
      height="75vh"
      @current-change="handleCurrentChange"
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55" />
      <el-table-column
        label="Название"
        prop="title" />
      <el-table-column
        label="Анонс текст"
        prop="anounceText" />
      <el-table-column
        label="Дата"
        prop="date" />
      <el-table-column
        label="Активность"
        prop="active">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.active ? 'success' : 'warning'"
            effect="plain">
            {{ scope.row.active ? 'Активна' : 'Не активна' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="right">
        <template slot-scope="scopeData">
          <router-link
            :to="`/news-edit/${scopeData.row.newsId}`">
            <el-button type="primary">
              Редактировать
              <i class="el-icon-arrow-right" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import tableData from './data.js';

export default {
  name: 'NewsList',
  data() {
    return {
      tableData,
      isDisableSave: true,
      search: '',
    };
  },
  computed: {
    ...mapGetters(['landinglist']),
  },
  mounted() {
    this.setPageTitle('Новости');
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    deleteSelected() {

    },
    handleCurrentChange() {
      // this.$router.push(`/landing/${val.id}`)
    },
    handleSelectionChange() {

    },
  },
};
</script>

<style lang="scss">
.news-list {
  .right-text {
    text-align: right;
  }
  .el-table__row {
    cursor: pointer;
  }
}
</style>
