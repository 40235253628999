export default [
  {
    newsId: '123123123',
    title: 'заголовок',
    date: '11.22.2222',
    anounceText:
      'qweqwew qwe qwe qwe qweqwe qweqw eqw eq eqweq weqweqweqw eqwe ',
    active: true,
  },
  {
    title: 'заголовок',
    date: '11.22.2222',
    anounceText:
      'qweqwew qwe qwe qwe qweqwe qweqw eqw eq eqweq weqweqweqw eqwe ',
    active: false,
  },
  {
    title: 'заголовок',
    date: '11.22.2222',
    anounceText:
      'qweqwew qwe qwe qwe qweqwe qweqw eqw eq eqweq weqweqweqw eqwe ',
    active: true,
  },
  {
    title: 'заголовок',
    date: '11.22.2222',
    anounceText:
      'qweqwew qwe qwe qwe qweqwe qweqw eqw eq eqweq weqweqweqw eqwe ',
    active: false,
  },
  {
    title: 'заголовок',
    date: '11.22.2222',
    anounceText:
      'qweqwew qwe qwe qwe qweqwe qweqw eqw eq eqweq weqweqweqw eqwe ',
    active: true,
  },
];
